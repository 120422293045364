/* styles.css */

.header-text {
  display: inline-block;
  font-size: 2em;
  text-align: center;
}

.header-text span {
  display: inline-block;
  opacity: 0;
  transform: translateX(-1em);
  animation: appear 0.5s forwards cubic-bezier(0.25, 1, 0.5, 1); /* Added a smooth timing function */
}

@keyframes appear {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
