.header-text {
    font-size: 65px;
    font-weight: lighter;
    letter-spacing: 0.2em;
 }
 
 @media only screen and (max-width: 600px) {
    .header-text {
       font-size: 40px;
    }
 }
 
 @media only screen and (max-width: 400px) {
    .header-text {
       font-size: 30px;
    }
 }
 